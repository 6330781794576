import React from 'react';
import LessonLayout from '../../components/LessonLayout';
import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import LessonList from '../../components/LessonList';

const PreschoolLessons = () => {
  const lessons = [
    {
      name: 'Preschool Group Lessons',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Group Lessons"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: 'Maximum of 4 Students Per Group',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
      ],
    },
    {
      name: 'Preschool Private 1 - 1',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '1 Student Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
    {
      name: 'Preschool Private 1 - 2',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '2 Students Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
  ];

  return (
    <LessonLayout styles="lessons" title="Preschool Swimming Lessons">
      <LessonList lessons={lessons} />
    </LessonLayout>
  );
};

export default PreschoolLessons;
